import URLs from '@/urls';
import { EntityBase } from '@/commons/EntityBase.js';
import { isPresent } from '@/components/Utils/utils';
import constants from '@/commons/constants';
import utils from '@/components/Utils/utils';
import moment from 'moment-timezone';
import i18n from '@/resources/i18n.js';


export class MessageEntity extends EntityBase {
    apiURLs = {
        listAll: URLs.MESSAGE,
        save: URLs.MESSAGE,
        update: URLs.MESSAGE,
        delete: URLs.MESSAGE,
        agentInformationsList: URLs.MESSAGE_AGENT_INFORMATIONS_LIST,
        directMessageUsersList: URLs.MESSAGE_DIRECT_USERS,
        sendDirectMessage: URLs.MESSAGE_DIRECT_MESSAGE_SEND,
    };

    constructor(log, store) {
        super(log, store);
    }

    createEntityObj(entity = null) {
        let returnObj = {};

        returnObj.id = isPresent(entity) && isPresent(entity.id) ? entity.id : '';
        returnObj.title = isPresent(entity) && isPresent(entity.title) ? entity.title : '';
        returnObj.subject = isPresent(entity) && isPresent(entity.subject) ? entity.subject : '';
        returnObj.lead = isPresent(entity) && isPresent(entity.lead) ? entity.lead : '';
        returnObj.body = isPresent(entity) && isPresent(entity.body) ? entity.body : '';
        returnObj.status = isPresent(entity) && isPresent(entity.status) ? entity.status : constants.MESSAGES_STATUSES[0].key;
        returnObj.created_at = isPresent(entity) && isPresent(entity.created_at) ? entity.created_at : '';
        returnObj.start_sending_at = isPresent(entity) && isPresent(entity.start_sending_at) ? moment(entity.start_sending_at).format(i18n.t("common.defaultDateTimeFormat.HU")) : '';
        if (isPresent(entity) && isPresent(entity.participants) && isPresent(entity.participants.agents)) {
            returnObj.agents = entity.participants.agents;
        } else if (isPresent(entity) && isPresent(entity.agents)) {
            returnObj.agents = entity.agents;
        } else {
            returnObj.agents = [];
        }
        if (isPresent(entity) && isPresent(entity.participants) && isPresent(entity.participants.agent_groups)) {
            returnObj.agent_groups = entity.participants.agent_groups;
        } else if (isPresent(entity) && isPresent(entity.agent_groups)) {
            returnObj.agent_groups = entity.agent_groups;
        } else {
            returnObj.agent_groups = [];
        }
        return returnObj;
    }

    agentInformationsList(entity, idFieldName = 'id') {
        let messageId = entity.id;
        let url = eval(`\`${this.apiURLs.agentInformationsList}\``);

        this.log.debug('EntityBase of ' + this.constructor.name + ' - agentInformationsList [ ' + url + ' ]');
        return utils.apiCaller(constants.HTTP_METHODS.GET, url);
    }

    directMessageUserList() {
        let url = this.apiURLs.directMessageUsersList;

        this.log.debug('EntityBase of ' + this.constructor.name + ' - directMessageUserList [ ' + url + ' ]');
        return utils.apiCaller(constants.HTTP_METHODS.GET, url);
    }

    sendDirectMessage(entity) {
        let url = this.apiURLs.sendDirectMessage;

        this.log.debug('EntityBase of ' + this.constructor.name + ' - sendDirectMessage [ ' + url + ' ]');
        return utils.apiCaller(constants.HTTP_METHODS.POST, url, entity);
    }

    createEntityObjSaveEdit(entity = null) {
        let createEntityObj = this.createEntityObj(entity);
        let returnObj = { ...createEntityObj };
        returnObj.start_sending_at = isPresent(createEntityObj.start_sending_at) ? createEntityObj.start_sending_at + ":00" : '';
        returnObj.participants = {};
        returnObj.participants.agents = createEntityObj.agents.map(el => ({ id: el.id }));
        returnObj.participants.agent_groups = createEntityObj.agent_groups.map(el => ({ id: el.id }));

        return returnObj;
    }
}
