import DataComponentBase from '@/commons/DataComponentBase';
import MessageDetails from '@/components/Messages/MessageDetails';
import VerificationDialog from '@/components/CustomVerificationDialog';
import { MessageEntity } from '@/components/Messages/MessageEntity';
import { isPresent } from '@/components/Utils/utils';


export default {
    name: 'MessageList',
    extends: DataComponentBase,
    components: {
        MessageDetails,
        VerificationDialog
    },
    props: {
    },

    data() {
        return {
            // tableItems: [],
            headerFilters: {
                id: "",
                created_at: "",
                start_sending_at: "",
                title: "",
                status: "",
            },
            // selectedItem: {},
            dialog: {
                details: false,
                edit: false,
                new: false,
                delete: false
            },
            entityHandler: new MessageEntity(this.$log, this.$store),
        }
    },

    created() {
        // this.initMessageList();
    },
    mounted() {
    },

    computed: {
        headers() {
            let headers = [
                {
                    text: this.$i18n.t('common.id'),
                    align: "right",
                    class: "width-89",
                    value: "id",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['id'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.title'),
                    align: "left",
                    value: "title",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['title'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.createdAt'),
                    align: "left",
                    value: "created_at",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['created_at'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.startSendingAt'),
                    align: "left",
                    value: "start_sending_at",
                    class: "width-205",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['start_sending_at'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.status'),
                    value: "status",
                    class: "width-205",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['status'].toLowerCase()) }
                },
            ];

            headers.push(
                {
                    text: "",
                    value: "actions",
                    sortable: true,
                    align: "center",
                    class: "action-column-3",
                }
            )
            return headers;
        },
        itemForDelete() {
            let res = isPresent(this.selectedEntity) && isPresent(this.selectedEntity.title) ? this.selectedEntity.title : "-";
            return res;
        }
    },

    methods: {
        openDialogNew() {
            this.clearDialog();
            this.dialog.edit = true;
            this.dialog.new = true;
            this.dialog.details = true;
        },
        openDialogDetails(entity) {
            this.dialog.edit = true;
            this.dialog.new = false;
            this.dialog.details = true;
            this.selectedEntity = entity;
        },
        openDialogView(entity) {
            this.dialog.edit = false;
            this.dialog.new = false;
            this.dialog.details = true;
            this.selectedEntity = entity;
        },
        openDialogDelete(entity) {
            this.dialog.delete = true;
            this.dialog.edit = false;
            this.dialog.new = false;
            this.dialog.details = false;
            this.selectedEntity = entity;
        },
        getStatus(status) {
            switch (status) {
                case "EDIT":
                    return { color: "warning" };
                    break;
                case "SENDING":
                    return { color: "success" };
                    break;
                case "POSTED":
                default:
                    return { color: "information" };
                    break;
            }
        },

    },
}
