import DialogBase from '@/commons/DialogBase';
import AgentList from '@/components/Agents/AgentList';
import { MessageEntity } from '@/components/Messages/MessageEntity';
import { AgentEntity } from '@/components/Agents/AgentEntity';
import { AgentGroupEntity } from '@/components/AgentGroups/AgentGroupEntity';
import moment from 'moment-timezone';


import { VueEditor } from "vue2-editor";

export default {
    name: 'MessageDetails',
    extends: DialogBase,
    components: {
        AgentList,
        VueEditor,
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        entity: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            form: {
                general: true,
                readership: true,
                table: true,
                directMessage: false
            },
            extraOffset: 120,
            headerFilters: {
                agent_name: "",
                partner_name: "",
                participant_as_group: "",
                sent_at: "",
                read_at: "",
            },
            agentGroups: [],
            agents: [],
            agentInformations: [],
            directMessageUserId: "",
            directMessageUsers: [],
            readonlyMode: false,
            clearableStarSendingAt: false,
            startSendingAtMenu: false,
            dateTimePickers: {
                start_sending_at: {
                    showMenu: false,
                    x: 0, 
                    y: 0,
                    date: moment().format(this.$i18n.t("common.onlyDateDateFormat.HU")),
                    time: moment().format(this.$i18n.t("common.shortTimeFormat.HU")),
                    dateTime: null
                },
            },
            customEditorToolbar: [
                [{ font: [] }],
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                ],
                [
                    { header: 1 },
                    { header: 2 },
                ],
                ["blockquote", "code-block"],
                [
                    { list: "ordered" },
                    { list: "bullet" },
                    { list: "check" }
                ],
                [
                    { script: "sub" },
                    { script: "super" }
                ],
                [
                    { indent: "-1" },
                    { indent: "+1" }
                ],
                [{ color: [] }, { background: [] }],
                ["link", "image", "video", "formula"],
                [{ direction: "rtl" }],
                ["clean"]
            ],
            entityHandler: new MessageEntity(this.$log, this.$store),
            entityHandlerAgent: new AgentEntity(this.$log, this.$store),
            entityHandlerAgentGroup: new AgentGroupEntity(this.$log, this.$store),
        }
    },

    created() {
        this.initDirectMessageUsers();
    },

    computed: {
        isEdit() {
            return this.value.edit;
        },
        isNew() {
            return this.value.new;
        },
        isOpenDetails() {
            return this.value.details;
        },
        headers() {
            let headers = [
                {
                    text: this.$i18n.t('common.name'),
                    align: "left",
                    value: "agent_name",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['agent_name'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.partner'),
                    align: "left",
                    value: "partner_name",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['partner_name'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.agentGroup'),
                    align: "left",
                    value: "participant_as_group",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['participant_as_group'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.sentAt'),
                    align: "left",
                    value: "sent_at",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['sent_at'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.readAt'),
                    align: "left",
                    value: "read_at",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['read_at'].toLowerCase()) }
                },
            ];

            return headers;
        },
    },

    methods: {
        initIsActive() {
            if (this.editedEntity.active == 'Y') {
                return true;
            } else {
                return false;
            }
        },
        initAgentGroups() {
            let agentGroups = [];
            this.entityHandlerAgentGroup.listAll().then(entities => {
                agentGroups = entities.data.data;
                this.agentGroups = agentGroups.map(el => {
                    return {
                        id: el.id,
                        name: el.name,
                    }
                });
            });
        },
        initAgents() {
            let agents = [];
            this.entityHandlerAgent.setPartnerId("");
            this.entityHandlerAgent.listAll().then(entities => {
                agents = entities.data.data;
                this.agents = agents.map(el => {
                    return {
                        id: el.id,
                        name: el.last_name + ' ' + el.first_name,
                        email: el.email
                    }
                });
            });
        },
        initAgentInformations(entity) {
            let agentInformations = [];
            this.entityHandler.agentInformationsList(entity).then(entities => {
                agentInformations = entities.data.data.statistic;
                this.agentInformations = entities.data.data.statistic;
                // this.agentInformations = agentInformations.statistic.map(el => ({
                //         id: el.id,
                //         name: el.agent_name,
                //         partner_name: el.partner_name,
                //         participant_as_group: el.participant_as_group,
                //         created_at: el.created_at,
                //         sent_at: el.sent_at,
                //         read_at: el.read_at
                // }));
            });
        },

        getStatus(status) {
            switch (status) {
                case "EDIT":
                    return { color: "warning" };
                    break;
                case "POSTED":
                    return { color: "grey" };
                    break;
                case "SENDING":
                    return { color: "success" };
                    break;
                default:
                    return { color: "information" };
                    break;
            }
        },
        openDialogDetails(entity) {
            this.loadEntityData(entity);

            if (this.isEdit) { 
                this.value.edit = true;
                this.value.details = true;
                this.agentInformations = [];
            } else {
                this.initAgentInformations(this.entity);

                this.value.edit = false;
                this.value.details = true;
            }
        },

        loadEntityData(entity) {
            this.entityHandler.getEntity(entity).then(entityData => {
                this.editedEntity = this.entityHandler.createEntityObj(entityData);
            });
        },

        initDirectMessageUsers() {
            this.entityHandler.directMessageUserList().then(entityData => {
                this.directMessageUsers = entityData.data.data;
            });
        },

        _saveNewEntityCreateEntityObj(editedEntity) {
            return this.entityHandler.createEntityObjSaveEdit(editedEntity)
        },

        _saveEditedEntityCreateEntityObj(editedEntity) {
            return this.entityHandler.createEntityObjSaveEdit(editedEntity)
        },
        showValidDateTimePicker(e, field) {
            this.$log.debug("#-------showValidDateTimePicker[" + field + "]: #", this.editedEntity, "#");
            this.$log.debug("#-------showValidDateTimePicker[" + field + "]: #", field, "#");
            this.$log.debug("#-------showValidDateTimePicker[" + field + "]: #", this.editedEntity[field], "#");
            this.$log.debug("#-------showValidDateTimePicker[" + field + "] 2: ", moment().format(this.$i18n.t("common.defaultDateTimeFormat.HU")));

            this.dateTimePickers[field].showMenu = false;
            if (this.$refs["input-" + field].valid) {
                this.dateTimePickers[field].date =
                    this.$utils.isPresent(this.editedEntity) &&
                        this.$utils.isPresent(this.editedEntity[field]) && this.editedEntity[field] != ""
                        ? this.$formatters.formatDate(this.editedEntity[field], this.$i18n.t("common.onlyDateDateFormat.HU")) : moment().format(this.$i18n.t("common.onlyDateDateFormat.HU"));
                this.$log.debug("-------showValidDateTimePicker[" + field + "]: ", this.dateTimePickers[field].date);
                this.dateTimePickers[field].time =
                    this.$utils.isPresent(this.editedEntity) &&
                        this.$utils.isPresent(this.editedEntity[field]) && this.editedEntity[field] != ""
                        ? this.$formatters.formatDate(this.editedEntity[field], this.$i18n.t("common.shortTimeFormat.HU")) : moment().format(this.$i18n.t("common.shortTimeFormat.HU"));
            }

            e.preventDefault();
            this.dateTimePickers[field].x = e.clientX;
            this.dateTimePickers[field].y = e.clientY;

            this.$nextTick(() => {
                this.dateTimePickers[field].showMenu = true;
            });
        },
        setDateTime(field) {
            this.$log.debug("------------1#setDateTime");
            const datetime = this.dateTimePickers[field].date + "T" + this.dateTimePickers[field].time + ":00";
            this.$log.debug("------------1#setDateTime", datetime);
            this.dateTimePickers[field].dateTime = this.$formatters.formatDate(datetime, this.$i18n.t("common.defaultDateTimeFormat.HU"));
            this.$log.debug("------------1#setDateTime2", this.dateTimePickers[field].dateTime);
            this.dateTimePickers[field].showMenu = false;
            this.editedEntity[field] = this.$formatters.formatDate(datetime, this.$i18n.t("common.defaultDateTimeFormat.HU"));
        },

        sendDirectMessage() {
            this.$log.debug("sendDirectMessage");

            if (this.$refs.form.validate()) {
                let diredctMessageEntity = {
                    user_id: this.directMessageUserId,
                    push_message_id: this.editedEntity.id
                }
                this.entityHandler
                    .sendDirectMessage(diredctMessageEntity)
                    .then(response => {
                        this.$store.commit('setStatusCode', response.status);
                        this.$store.commit('setSnackbarEnabled', true);
                    })
                    .catch(error => {
                        this.$store.commit('setStatusCode', response.status);
                        this.$store.commit('setSnackbarEnabled', true);
                        this.$log.error(error);
                    })
                    .finally(() => {});
            } else {
                this.$store.commit('setStatusCode', 'invalidFormData');
                this.$store.commit('setSnackbarEnabled', true);
            }
        }
    },

    watch: {
        isOpenDetails() {
            if (this.isOpenDetails) {
                this.form.general = true;
                this.form.table = true;

                this.initIsActive();
                this.initAgentGroups();
                this.initAgents();

                if (this.isNew) {
                    this.openDialogNew(this.entity);
                } else {
                    this.openDialogDetails(this.entity);
                }

                //this.$nextTick(() => this.$refs.agent_form_firm_name.focus());

            }
        },
    }

}
