<template>
  <v-container
    id="messages"
    fluid
    class="page-container">
    <v-row>
      <v-col>

        <MessageList></MessageList>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import DataComponentBase from '@/commons/DataComponentBase';
  import MessageList from '@/components/Messages/MessageList';

  export default {
    name: 'Messages',
    extends: DataComponentBase,
    components: {
      MessageList,
    },

    data () {
      return {
      }
    },

    computed: {
    },

    methods: {
    },

  };
</script>

